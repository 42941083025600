<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        At
        <stemble-latex content="$25^\circ\text{C,}$" />
        how high will water rise (in meters) in a glass capillary tube with an inner diameter of
        <number-value :value="diameter" unit="\text{mm?}" />
        Recall that the surface tension of water at this temperature is
        <stemble-latex content="$71.99\,\text{mN/m}$" />
        and the density is
        <stemble-latex content="$1.000\,\text{g/cm}^3.$" />
      </p>

      <calculation-input
        v-model="inputs.height"
        prepend-text="$\text{h:}$"
        append-text="$\text{m}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question399',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        height: null,
      },
    };
  },
  computed: {
    diameter() {
      return this.taskState.getValueBySymbol('diameter').content;
    },
  },
};
</script>
